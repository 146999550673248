package pages

enum class PageType {
    HOME,
    PRODUCTS,
    ORDERS,
    MY_ORDER,
    FAQ,
    CONTACT,
    NOT_FOUND
}