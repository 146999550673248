import com.inficate.common.constants.Rest
import io.github.aakira.napier.Napier
import kotlinx.browser.window
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.url.URL
import pages.*
import kotlin.time.ExperimentalTime

@ExperimentalTime
fun main() {

    val homePath: String = "/home"
    val productsPath: String = "/products"
    val ordersPath: String = "/orders"
    val faqPath: String = "/faq"
    val contactPath: String = "/contact"


    fun initNav() {
        val homeAnchor: HTMLAnchorElement = window.document.getElementById("home") as HTMLAnchorElement
        homeAnchor.addEventListener("click", { event ->
            event.preventDefault()
            Napier.i("Home button clicked")
            window.history.pushState(null, "", homePath)
            Home().render()
        })

        val productsAnchor: HTMLAnchorElement = window.document.getElementById("products") as HTMLAnchorElement
        productsAnchor.addEventListener("click", { event ->
            event.preventDefault()
            Napier.i("Products button clicked")
            window.history.pushState(null, "", productsPath)
            Products().render()
        })

        val ordersAnchor: HTMLAnchorElement = window.document.getElementById("orders") as HTMLAnchorElement
        ordersAnchor.addEventListener("click", { event ->
            event.preventDefault()
            Napier.i("Orders button clicked")
            if (MyOrder.isInitialized()) {
                val baseUrl = "${window.location.protocol}//${window.location.host}/"
                window.history.pushState(null, "", baseUrl + Rest.ORDERS_URL + MyOrder.id)
                MyOrder().render()
            } else {
                window.history.pushState(null, "", ordersPath)
                Orders().render()
            }
        })

        val faqAnchor: HTMLAnchorElement = window.document.getElementById("faq") as HTMLAnchorElement
        faqAnchor.addEventListener("click", { event ->
            event.preventDefault()
            Napier.i("FAQ button clicked")
            window.history.pushState(null, "", faqPath)
            Faq().render()
        })

        val contactAnchor: HTMLAnchorElement = window.document.getElementById("contact") as HTMLAnchorElement
        contactAnchor.addEventListener("click", { event ->
            event.preventDefault()
            Napier.i("Contact button clicked")
            window.history.pushState(null, "", contactPath)
            Contact().render()
        })
    }

    Napier.base(Logger())

    val url: URL = URL(window.location.href)
    val segments: List<String> = url.pathname.split("/").filter { it.isNotEmpty() }

    initNav()

    val pageType: PageType = when {

        // For HOME.
        segments.isEmpty() -> PageType.HOME

        // For PRODUCTS.
        segments.firstOrNull() == "products" -> PageType.PRODUCTS

        // For ORDERS.
        segments.firstOrNull() == "orders" && segments.size == 1 -> PageType.ORDERS

        // For MY_ORDER.
        segments.firstOrNull() == "orders" && segments.size == 2 -> PageType.MY_ORDER

        // For FAQ.
        segments.firstOrNull() == "faq" -> PageType.FAQ

        // For CONTACT.
        segments.firstOrNull() == "contact" -> PageType.CONTACT

        else -> PageType.NOT_FOUND
    }

    Napier.i("Current URL: $url")
    Napier.i("Current Page Type: $pageType")

    when (pageType) {
        PageType.HOME -> {
            Home().render()
        }
        PageType.PRODUCTS -> {
            Products().render()
        }
        PageType.ORDERS -> {
            Orders().render()
        }
        PageType.MY_ORDER -> {
            MyOrder(segments).render()
        }
        PageType.FAQ -> {
            Faq().render()
        }
        PageType.CONTACT -> {
            Contact().render()
        }
        PageType.NOT_FOUND -> {
            NotFound(segments).render()
        }
    }
}
