package com.inficate.common.constants

object Http {
    const val HEADER_AUTHORIZATION: String = "authorization"
    const val HEADER_CONTENT_TYPE: String = "Content-Type"
    const val HEADER_ACCEPT: String = "Accept"
    const val HEADER_ACCESS_CONTROL_ALLOW_ORIGIN: String = "Access-Control-Allow-Origin"
    const val HEADER_ACCESS_CONTROL_ALLOW_CREDENTIALS: String = "Access-Control-Allow-Credentials"
    const val HEADER_ACCESS_CONTROL_ALLOW_HEADERS: String = "Access-Control-Allow-Headers"
    const val HEADER_ACCESS_CONTROL_ALLOW_METHODS: String = "Access-Control-Allow-Methods"
    const val APPLICATION_JSON: String = "application/json"
}
