import com.inficate.common.Order
import com.inficate.common.constants.OrderStatus
import io.github.aakira.napier.Napier
import kotlinx.browser.window
import kotlin.js.Promise
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds
import kotlin.time.ExperimentalTime

@ExperimentalTime
class OrderPoller(
    additionalDelay: Duration,
    private val maxDelay: Duration = 300.seconds) {

    private val internalDelay: Duration = 30.seconds
    private val totalDelay: Duration

    init {
        totalDelay = additionalDelay + internalDelay
    }

    private var currentDelay: Duration = totalDelay
    private var timeoutId: Int? = null

    fun poll(
        action: () -> Promise<Order?>,
        onNewResult: (Order) -> Unit) {

        if (timeoutId != null) {
            throw IllegalStateException("A task is already scheduled")
        }

        currentDelay = totalDelay
        pollWithBackoff(action, onNewResult)
    }

    private fun pollWithBackoff(
        action: () -> Promise<Order?>,
        onNewResult: (Order) -> Unit) {

        timeoutId = window.setTimeout({
            action.invoke().then { result ->
                result?.let { order ->
                    if (OrderStatus.fromString(order.status) != OrderStatus.DRAFTING) {
                        timeoutId = null
                        Napier.i("Order found and polling stopped")
                        onNewResult(order)
                    } else {
                        Napier.i("Order polled but no new result")
                        currentDelay = (currentDelay * 1.1).coerceAtMost(maxDelay)
                        pollWithBackoff(action, onNewResult)
                    }
                }
            }.catch {
                Napier.e("Error while polling for order: $it")
                currentDelay = (currentDelay * 1.1).coerceAtMost(maxDelay)
                pollWithBackoff(action, onNewResult)
            }
        }, currentDelay.inWholeMilliseconds.toInt())
    }
}