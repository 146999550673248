package pages

import OrderPoller
import SingleView
import com.inficate.common.Order
import com.inficate.common.constants.Http
import com.inficate.common.constants.OrderStatus
import com.inficate.common.constants.Rest
import io.github.aakira.napier.Napier
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.fetch.*
import kotlin.js.Promise
import kotlin.js.json
import kotlin.properties.Delegates
import kotlin.reflect.KProperty
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds
import kotlin.time.ExperimentalTime

@ExperimentalTime
class MyOrder : SingleView {

    companion object {

        lateinit var id: String

        fun isInitialized() = ::segments.isInitialized

        private lateinit var segments: List<String>

        private val MIDJOURNEY_POLLING_INITIAL_DELAY: Duration = 30.seconds
    }

    private val renderedUrls = mutableSetOf<String>()

    private var orderStatus: OrderStatus by Delegates.observable<OrderStatus>(OrderStatus.UNDEFINED) {
            _: KProperty<*>,
            oldValue: OrderStatus,
            newValue: OrderStatus ->

        Napier.i("OrderStatus changed from $oldValue to $newValue")

        val promptButton: HTMLButtonElement =
            window.document.querySelector("button[name='prompt-button']") as HTMLButtonElement
        when (newValue) {
            OrderStatus.DRAFTING -> {
                promptButton.classList.add("button-loading")
                promptButton.disabled = true
            }
            OrderStatus.DRAFTED -> {
                promptButton.classList.remove("button-loading")
                promptButton.disabled = false
            }
            OrderStatus.PICKED -> {
                showPick()
            }
            else -> {}
        }
    }

    private lateinit var order: Order

    constructor(segments: List<String>) : super() {
        MyOrder.segments = segments
        init()
    }

    constructor() : super() {
        if (!isInitialized()) {
            throw IllegalStateException("MyOrder not initialized with segments")
        }
        init()
    }

    private fun init() {
        if (segments.size > 1) {
            id = segments[1]
        } else {
            throw IllegalArgumentException("Order ID not found with segments $segments")
        }

        Napier.i("${this::class.simpleName} initialized with ID $id")
    }

    override fun render() {
        initHtml().then {
            initDynamics()
            initPrompt()
            initPick()
            initOrders()
        }
    }

    private fun initHtml(): Promise<Any?> {
        return Promise { resolve, reject ->
            window.onload = { _: Event ->
                try {
                    val containerDiv: HTMLDivElement =
                        window.document.querySelector(".main-container > div") as HTMLDivElement
                    val templateIFrame: HTMLIFrameElement =
                        window.document.querySelector("iframe[src='/template.html']") as HTMLIFrameElement
                    val contentDocument: Document = templateIFrame.contentDocument!!
                    val templateElement: Element = contentDocument.getElementById("my-order")!!
                    containerDiv.innerHTML = templateElement.innerHTML
                    Napier.i("HTML rendered for ${this::class.simpleName}")
                    resolve(null)
                } catch (e: Exception) {
                    reject(e)
                }
            }
        }
    }

    private fun initDynamics() {
        val promptButton: HTMLButtonElement =
            window.document.querySelector("button[name='prompt-button']") as HTMLButtonElement
        promptButton.classList.add("button-loading")
        promptButton.disabled = true

        Napier.i("Dynamics rendered for ${this::class.simpleName}")
    }

    private fun initOrders() {
        getOrderById().then { order ->
            order?.let {
                if (this.orderStatus == OrderStatus.DRAFTED) {
                    loadOrder(it)
                }
            } ?: Napier.e("Failed to retrieve the order.")
        }.catch { error ->
            Napier.e("Error while initializing orders: $error", error)
        }
    }

    private fun getOrderById(): Promise<Order?> {
        return window.fetch(Rest.ENDPOINT_ORDERS + id, init = RequestInit(mode = RequestMode.CORS))
            .then { response ->
                if (response.ok) {
                    response.json()
                } else {
                    Napier.e("Failed to fetch the order. Status: ${response.status}")
                    Promise.reject(Throwable("Failed to fetch the order"))
                }
            }.then { payload ->
                val order: Order = Json.decodeFromString<Order>(JSON.stringify(payload))
                this.order = order
                Napier.i("Order ${this.order} acquired")
            }.then {
                this.orderStatus = OrderStatus.fromString(this.order.status)
                this.order
            }.catch { error ->
                Napier.e("Error: $error", error)
                null
            }
    }

    private fun loadOrder(order: Order) {
        val masonryDiv: HTMLDivElement = window.document.querySelector(".masonry") as HTMLDivElement
        val loadingDiv: HTMLDivElement = window.document.querySelector(".loading-screen") as HTMLDivElement
        val popupImg: HTMLImageElement = window.document.querySelector(".modal-body > img") as HTMLImageElement

        order.designs.forEach { design ->
            design.urls.forEach { url ->
                if (!renderedUrls.contains(url)) {
                    val img: HTMLImageElement = window.document.createElement("img") as HTMLImageElement
                    img.src = url
                    img.alt = design.originalPrompt

                    val div: HTMLDivElement = window.document.createElement("div") as HTMLDivElement
                    div.className = "box"
                    div.appendChild(img)
                    div.addEventListener("click", {
                        popupImg.src = img.src
                        val modal: dynamic = js("new bootstrap.Modal(document.querySelector('.popup'))")
                        modal.show() as Unit
                    })

                    masonryDiv.appendChild(div)

                    renderedUrls.add(url)
                }
            }
        }

        loadingDiv.style.display = "none"
        masonryDiv.style.display = "grid"

        Napier.i("Orders rendered for ${this::class.simpleName}")
    }

    private fun initPick() {
        val pickButton: HTMLButtonElement =
            window.document.querySelector("button[name='pick-button']") as HTMLButtonElement
        pickButton.addEventListener("click", {
            Napier.i("Pick button clicked")
            val popupImg: HTMLImageElement = window.document.querySelector("modal-body > img") as HTMLImageElement
            val url: String = popupImg.src
            val putOrderByIdRequestBody: String = JSON.stringify(json("pick" to url))
            this.orderStatus = OrderStatus.PICKED

            Napier.i("Picking $url for order $id")
            window.fetch(
                Rest.ENDPOINT_PUT_ORDER_BY_ID.replace(Rest.PLACE_HOLDER_ID, id),
                init = RequestInit(
                    mode = RequestMode.CORS,
                    method = "PUT",
                    headers = Headers().apply {
                        append(Http.HEADER_CONTENT_TYPE, Http.APPLICATION_JSON)
                    },
                    body = putOrderByIdRequestBody
                )
            ).then { response ->
                val payload: Promise<String> = response.text()
                Napier.i("PutOrderById returned with response: $payload")
            }
        })

        Napier.i("Pick rendered for ${this::class.simpleName}")
    }

    private fun initPrompt() {
        val promptTextarea: HTMLTextAreaElement =
            window.document.querySelector("textarea[name='prompt-textarea']") as HTMLTextAreaElement
        val promptButton: HTMLButtonElement =
            window.document.querySelector("button[name='prompt-button']") as HTMLButtonElement

        promptButton.addEventListener("click", {
            Napier.i("Prompt button clicked")

            val prompt: String = promptTextarea.value
            promptTextarea.value = ""

            val putOrderByIdRequestBody: String = JSON.stringify(json("prompt" to prompt))
            this.orderStatus = OrderStatus.DRAFTING

            Napier.i("Sending request PutOrderById with $putOrderByIdRequestBody")
            window.fetch(
                Rest.ENDPOINT_PUT_ORDER_BY_ID.replace(Rest.PLACE_HOLDER_ID, id),
                init = RequestInit(
                    mode = RequestMode.CORS,
                    method = "PUT",
                    headers = Headers().apply {
                        append(Http.HEADER_CONTENT_TYPE, Http.APPLICATION_JSON)
                    },
                    body = putOrderByIdRequestBody
                )
            ).then { response ->
                val payload: Promise<String> = response.text()
                Napier.i("PutOrderById returned with response: $payload")
            }.then {
                Napier.i("Scheduling MidjourneyPoller in $MIDJOURNEY_POLLING_INITIAL_DELAY")
                val postPollersRequestBody: String = JSON.stringify(json("id" to id))
                window.setTimeout({
                    window.fetch(
                        Rest.ENDPOINT_POLLERS,
                        init = RequestInit(
                            mode = RequestMode.CORS,
                            method = "POST",
                            headers = Headers().apply {
                                append(Http.HEADER_CONTENT_TYPE, Http.APPLICATION_JSON)
                            },
                            body = postPollersRequestBody
                        )
                    ).then { response ->
                        response.text()
                    }.then { payload ->
                        Napier.i("PostPollers succeeded with $payload")
                    }.catch { error ->
                        Napier.e("Error calling the API", error)
                    }
                }, MIDJOURNEY_POLLING_INITIAL_DELAY.inWholeMilliseconds.toInt())
                Napier.i("Scheduled MidjourneyPoller")
            }.then {
                Napier.i("Scheduling order polling")
                OrderPoller(MIDJOURNEY_POLLING_INITIAL_DELAY).poll(
                    {
                        getOrderById()
                    },
                    { order ->
                        this.orderStatus = OrderStatus.fromString(order.status)
                        loadOrder(order)
                    }
                )
                Napier.i("Scheduled order polling")
            }.catch { error ->
                Napier.e("Error calling the API", error)
            }
        })

        Napier.i("Prompt rendered for ${this::class.simpleName}")
    }

    private fun showPick() {
        val masonryDiv: HTMLDivElement = window.document.querySelector("div.masonry") as HTMLDivElement
        val popupDiv: HTMLDivElement = window.document.querySelector("div.popup") as HTMLDivElement
        val bannerDiv: HTMLDivElement = window.document.querySelector("div.banner") as HTMLDivElement
        val loadingDiv: HTMLDivElement = window.document.querySelector("div.loading-screen") as HTMLDivElement
        masonryDiv.style.display = "none"
        popupDiv.style.display = "none"
        bannerDiv.style.display = "none"
        loadingDiv.style.display = "none"

        val popupImg = window.document.querySelector("modal-body > img") as HTMLImageElement
        val pickDiv: HTMLDivElement = window.document.createElement("div") as HTMLDivElement
        val pickImg: HTMLImageElement = window.document.createElement("img") as HTMLImageElement
        pickImg.src = popupImg.src.ifEmpty { this.order.pick }
        pickDiv.className = "pick"
        pickDiv.appendChild(pickImg)
        val containerDiv: HTMLDivElement = window.document.querySelector(".main-container > div") as HTMLDivElement
        containerDiv.appendChild(pickDiv)

        Napier.i("Pick shown for ${this::class.simpleName}")
    }
}