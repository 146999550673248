package com.inficate.common

import kotlinx.serialization.Serializable

@Serializable
data class Design(
    var originalPrompt: String = "",
    var refinedPrompt: String = "",
    var rank: String = "",
    var urls: List<String> = emptyList()
)
