package com.inficate.common.constants

object Rest {
    const val PLACE_HOLDER_ID: String = "{id}"
    const val BASE_URL: String = "https://api.inficate.com/"
    const val ORDERS_URL = "orders/"
    const val POLLERS_URL = "pollers/"
    const val ENDPOINT_POLLERS: String = BASE_URL + POLLERS_URL
    const val ENDPOINT_ORDERS: String = BASE_URL + ORDERS_URL
    const val ENDPOINT_PUT_ORDER_BY_ID: String = "$ENDPOINT_ORDERS$PLACE_HOLDER_ID/";
}
