package pages

import SingleView
import kotlinx.browser.window

class Products : SingleView() {

    private val productsUrl = "https://inficate.etsy.com"

    override fun render() {
        window.open(productsUrl, "_blank")
        window.history.back()
    }
}