import io.github.aakira.napier.Antilog
import io.github.aakira.napier.LogLevel
import kotlin.js.Date

class Logger : Antilog() {
    private fun currentTimestamp(): String {
        val date: Date = Date()
        return date.toISOString()
    }

    override fun performLog(
        priority: LogLevel,
        tag: String?,
        throwable: Throwable?,
        message: String?
    ) {
        val timestamp: String = currentTimestamp()
        val logMessage: String = "[$timestamp] ${priority.name} ${tag.orEmpty()}: ${message.orEmpty()}"
        console.log(logMessage)
        throwable?.printStackTrace()
    }
}