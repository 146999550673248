package com.inficate.common

import kotlinx.serialization.Serializable

@Serializable
data class Order(
    var id: String = "",
    var designs: List<Design> = emptyList(),
    var draft: Design = Design(),
    var pick: String = "",
    var status: String = "",
    var events: List<List<String>> = emptyList()
)