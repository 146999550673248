package com.inficate.common.constants

enum class OrderStatus(val value: String) {
    RECEIVED("Received"),
    DRAFTING("Drafting"),
    DRAFTED("Drafted"),
    PICKED("Picked"),
    FINALIZED("Finalized"),
    MANUFACTURING("Manufacturing"),
    SHIPPING("Shipping"),
    SHIPPED("Shipped"),
    RESTOCKING("Restocking"),
    COMPLETED("Completed"),
    ARCHIVED("Archived"),
    CANCELED("Canceled"),
    RETURNED("Returned"),
    REFUNDED("Refunded"),
    SUSPENDED("Suspended"),
    ERROR("Error"),
    UNDEFINED("Undefined");

    override fun toString() = value

    companion object {
        fun fromString(value: String): OrderStatus {
            return entries.find { it.value == value } ?: UNDEFINED
        }
    }
}
